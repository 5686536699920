$delay: 0.275s;

@keyframes react-contexify__fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-contexify__fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.react-contexify__will-enter--fade {
  animation: react-contexify__fadeIn $delay ease;
}

.react-contexify__will-leave--fade {
  animation: react-contexify__fadeOut $delay ease;
}

.react-contexify {
  position: fixed;
  opacity: 0;
  user-select: none;
  color: black;
  background-color: white;
  box-shadow: 5px 5px 2px -3px rgba(0, 0, 0, 0.44);
  padding: 2px;
  border: 1px solid rgb(172, 168, 153);
  font-family: 'Tahoma';
  font-size: 11px;
  line-height: 11px;

  & &__submenu {
    position: absolute;
    top: 0;
    pointer-events: none;
    transition: opacity $delay;
  }

  &__submenu-arrow {
    font-size: 7px;
    position: absolute;
    right: 5px;
  }

  &__separator {
    float: left;
    width: 100%;
    height: 1px;
    margin: 3px 0;
    padding: 0 1px;
    background-color: rgb(172, 168, 153);
    background-clip: content-box;
  }

  &__item {
    position: relative;
    pointer-events: auto;

    &:hover > &__content {
      background-color: rgb(49, 106, 197);
    }

    &:not(&--disabled):hover > &__content {
      color: white;
    }

    &:not(&--disabled):hover > .react-contexify__submenu {
      pointer-events: initial;
      opacity: 1;
    }

    &--disabled {
      cursor: default;
      color: rgb(172, 168, 153);
    }

    &__content {
      padding: 3px 22px 3px 17px;
      display: flex;
      text-align: left;
      white-space: nowrap;
      position: relative;
    }

    &__icon {
      font-size: 20px;
      margin-right: 5px;
      font-style: normal;
    }
  }
}
